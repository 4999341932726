<template>
  <section :style="{ padding: '10px' }">
    <Modal :toggle.sync="toggle">
      <h1>Modal</h1>
      <div :style="{ height: '2000px', width: '100%' }"></div>
    </Modal>

    <b-button @click="toggle = true">Tall</b-button>
  </section>
</template>

<script>
import Modal from '../index.vue'

export default {
  components: {
    Modal
  },
  data() {
    return {
      toggle: false
    }
  }
}
</script>
