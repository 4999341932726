<template>
  <div :style="{ padding: '100px' }">
    <h1>Modals</h1>

    <Simple />
    <Tall />
    <WithFooter />
    <WithFooterAndHeader />
    <WithValidation />
    <Loading />
  </div>
</template>

<script>
import Simple from './Simple'
import Tall from './Tall'
import WithFooter from './WithFooter'
import WithFooterAndHeader from './WithFooterAndHeader'
import WithValidation from './WithValidation'
import Loading from './Loading'

export default {
  components: {
    Simple,
    Tall,
    WithFooter,
    WithFooterAndHeader,
    WithValidation,
    Loading
  }
}
</script>
