<template>
  <section :style="{ padding: '10px' }">
    <Modal :toggle.sync="toggle">
      <h1>Modal</h1>
      <div :style="{ height: '2000px', width: '100%' }"></div>

      <template v-slot:header>label</template>

      <template v-slot:footer>
        <b-button @click="toggle = false">Close</b-button>
      </template>
    </Modal>

    <b-button @click="toggle = true">Modal with Footer and Header</b-button>
  </section>
</template>

<script>
import Modal from '../index.vue'

export default {
  components: {
    Modal
  },
  data() {
    return {
      toggle: false
    }
  }
}
</script>
