<template>
  <section :style="{ padding: '10px' }">
    <Modal :toggle.sync="toggle">
      <h1>Modal</h1>

      <ValidationObserver ref="observer">
        <ValidInput
          v-model="formData.note"
          rules="required|max:1"
          name="Note"
          label="Note"
          maxlength="8000"
          type="textarea"
          vid="note"
          placeholder="Note"
          spellcheck="true"
          aria-label="Note"
        />
      </ValidationObserver>

      <template v-slot:footer>
        <b-button @click="validate()">Save</b-button>
      </template>
    </Modal>

    <b-button @click="toggle = true">Modal With Validation</b-button>
  </section>
</template>

<script>
import Modal from '../index.vue'
import ValidInput from '@/components/inputs/ValidInput'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    Modal,
    ValidInput,
    ValidationObserver
  },
  data() {
    return {
      toggle: false,
      formData: {
        note: ''
      }
    }
  },
  methods: {
    async validate() {
      const isValid = await this.$refs.observer.validate()

      console.log({ isValid })

      if (isValid) {
        this.toggle = false
      }
    }
  }
}
</script>
